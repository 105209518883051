import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import parksReducer from './parks';
import reviewsReducer from './review';
import sessionReducer from './session'
import trailsReducer from './trails';

const rootReducer = combineReducers({
    session: sessionReducer,
    trails: trailsReducer,
    parks: parksReducer,
    reviews: reviewsReducer
});

let enhancer;

if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
  } else {
    const logger = require('redux-logger').default;
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
  }

const configureStore = (preloadedState={}) => {
    return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;